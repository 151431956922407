.login_ctr {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
}
.login_ctr > div:first-child {
  height: 100%;
  flex: 1;
}
.login_ctr > div:nth-child(2) {
  flex: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_ctr > div:first-child > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.login_form {
  width: 80%;
  margin: 5% auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.login-inputs-ctr {
  flex: 2;
  width: 100%;
}
.login-btn-ctr {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login-logo-ctr {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-logo-ctr > img {
  height: 75%;
  width: 75%;
  object-fit: contain;
}
/*button*/

.btn-go {
  cursor: pointer;
  background: #3f51b5;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.5em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #3f51b5;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
}

.btn-go-icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.5em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #3f51b5;
  right: 0.3em;
  transition: all 0.3s;
}

.btn-go:hover .btn-go-icon {
  width: calc(100% - 0.6em);
}

.btn-go .btn-go-icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #3f51b5;
}

.btn-go:hover .btn-go-icon svg {
  transform: translateX(0.1em);
}

.btn-go:active .btn-go-icon {
  transform: scale(0.95);
}

/*button*/

/**
media queries--->
*/
@media screen and (max-width: 2560px) {
  .form_ctr {
    width: 500px;
    height: 60%;
  }
}

@media screen and (max-width: 1500px) {
  .form_ctr {
    width: 350px;
    height: 70%;
  }
}

@media screen and (max-width: 1024px) {
  .form_ctr {
    width: 300px;
    height: 75%;
  }
}

@media screen and (max-width: 768px) {
  .login_ctr > div:first-child {
    display: none;
  }
  .form_ctr {
    width: 300px;
  }
}
