.sidebar {
  height: calc(100vh - 100px);
}
.sidebar-content {
  width: 195px;
  height: 95%;
  margin: 15px auto;
}
.sidebar-hdr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.sidebar-hdr > img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  border-radius: 50%;
}
.sidebar-hdr > span {
  font-size: 18px;
  font-weight: 550;
  padding: 5px 0px;
}
.sidebar-link {
  cursor: pointer;
  padding: 10px;
  margin: 10px 15px;
  border-radius: 5px;
}
.sidebar-link:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar-link.active {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar-link > i {
  font-size: 20px;
  margin: 2px 5px;
}
.sidebar-link > span {
  font-size: 15px;
  margin: 2px 2px 5px 2px;
}
