.topbar {
  width: 100vw;
  max-width: 100%;
  height: 50px;
}
.topbar-ctr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  height: 100%;
  border-radius: 5px;
}
.topbar-left-ctr {
  display: flex;
  align-items: center;
}
.open_side_nav {
  margin-right: 15px;
}
.logo {
  font-size: 30px;
  font-weight: bold;
  color: darkblue;
}
.topbar-icons {
  display: flex;
}

.topbar-icon-wrapper {
  position: relative;
  margin: 0px 15px;
}
.topbar-icon-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #e0a70e;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

@media screen and (max-width: 500px) {
  .logo {
    display: none;
  }
}
